import React from 'react';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import globalJson from '../data/global.json';

const ContactPage = () => {
  return (
    <Layout pageWrapper="page-contactus">
      {/* CONTACT US */}
      <section className="main-padding mt-0 mt-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sectionTitle">
                <h2>How Can We Help?</h2>
                <p>Please fill out the information below with a brief description of the issue and we’ll contact you as soon as possible.</p>
              </div>
            </div>
            <div className="col-lg-8 mb-4 mb-lg-0">
              <ContactForm />
            </div>
            <div className="col-lg-4">
              {/* Contact Sidebar */}
              <div className="contact_sidebar">
                <div className="media-box bg-sand">
                  <div className="media-icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="media-content">
                    <h4>Phone</h4>
                    <p>
                      (262) 672-9291
                    </p>
                  </div>
                </div>
                <div className="media-box bg-sand">
                  <div className="media-icon">
                    <i className="fa fa-envelope" />
                  </div>
                  <div className="media-content">
                    <h4>Email</h4>
                    <p>
                      {globalJson.emailAddress}
                    </p>
                  </div>
                </div>
              </div>{/* Contact Sidebar Ends */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
